import "./contact.css";
import { AiOutlineYoutube } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import lightyellow from "./light-yellow.jpg"
import contact4 from "../props/contact4.jpg"

function Contact() {
  return (
    <div id="contact" className="container contact-container">
      <h2 className="animated rubberBand title_contact"> Contact Us</h2>
      <p className="animated bounceIn call_us">
        Call us:{" "}
        <a
          href="tel: +6434209888"
          style={{ color: "#a5836c", textDecoration: "none" }}
        >
          03 4209 888{" "}
        </a>
      </p>
      <p className="animated bounceIn email_us">
        Email us:{" "}
        <a
          href="mailto:newregentnail@gmail.com"
          style={{ color: "#a5836c", textDecoration: "none" }}
        >
          newregentnail@gmail.com{" "}
        </a>
      </p>
      <p className="animated bounceIn address_us">10 New Regent Street, Christchurch Central City, Christchurch, New Zealand</p>
      <img src={contact4} alt="" className="animated rubberBand img_us"/>
    </div>
  );
}

export default Contact;
