import "./about.css";
import Qualifications from "../qualifications/qualifications";

function About() {
  return (
    <div id="about" className="container about-container">
      <Qualifications />
    </div>
  );
}
export default About;
