import "./home.css";
import img from "../props/img2.jpg";
import logo from "../props/logo.png"
import Buttons from "../button/button";
import { BsMouse } from "react-icons/bs";
import bg from "../../asset/bg1.jpg";
import { fesha_url } from "../../fresha_url";

function Home() {
  return (
    <div
      id="home"
      className="container home-container animated bounceInDown"
      style={{
        width: "100%",
        height: "100vh",
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)), url(${bg}) center`,
      }}
    >
      <div className="logo">
        <div className="hover-show">
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
        </div>

        <img src={logo} alt="" />
      </div>

      {/* <a href="#footer" className="scroll-down">
        <hr />
        <h5>scroll down</h5>
        <BsMouse className="scroll" />
        <hr />
      </a> */}

      <h2>
        <h1 className="font_welcome">Welcome to</h1>
        <h1 className="font_gradient">New Regent Nails</h1>
        <h6 className="font_text">
          Nails are about something other than being noticed. It is about being
          remembered.
        </h6>
      </h2>

      <div style={{ display: "flex" }}>
        <a href={fesha_url} target="_blank" class="cta">
          <span>Book now</span>
          <svg width="13px" height="10px" viewBox="0 0 13 10">
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </a>
      </div>

    </div>
  );
}

export default Home;
