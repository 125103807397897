import "./footer.css";
import { BsMouse } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { TiSocialDribbble } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { TiSocialGithub } from "react-icons/ti";
import bg from "../../asset/bg1.jpg";

function Footer() {
  return (
    <div id="footer" className="footer-container"
    
    style={{
      width: "100%",
      // height: "100vh",
      background: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8)), url(${bg}) center`,
    }}
    
    >
      <p >
        New Regent Nails © 2023 designed by {" "}
        <a
          target="_blank"
          href="https://miraelite.com/"
          style={{
            textDecoration: "none",
            color: "#a5836c",
            // fontWeight: 600,
          }}
        >
          Mira Elite
        </a>{" "}
        with ❤️
      </p>
      <div className="social-links">
        <a href="#">
          <BsInstagram className="social" />
        </a>
        <a href="#">
          {" "}
          <FaFacebookF className="social" />
        </a>
        {/* <a href="#">
          {" "}
          <TiSocialDribbble className="social" />
        </a>

        <a href="#">
          <TiSocialYoutube className="social" />
        </a>

        <a href="#">
          <TiSocialGithub className="social" />
        </a> */}
      </div>
    </div>
  );
}
export default Footer;
